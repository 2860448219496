<template>
  <div class="container">
    <!--    表单-->
    <formCom :formList.sync="queryOrderForm" @query="getData" />
    <tabsCom
      :tabsNumber.sync="tabsNumber"
      :list="tabsList.queryOrder"
      @handleClick="handleClick"
    />
    <div class="order-list" v-if="data.length">
      <div
        class="order-item"
        style="padding: 20px; margin-bottom: 20px; box-shadow: 0 2px 4px #ccc"
        v-for="(item, index) of data"
        :key="index"
      >
        <div style="line-height: 40px">
          <span style="margin-right: 40px">订单号：{{ item[0].orderNo }}</span>
          <span style="margin-right: 40px">下单人：{{ item[0].userName }}</span>
          <span style="margin-right: 40px">
            联系电话：{{ item[0].userPhone || "无" }}</span
          >
        </div>
        <div style="line-height: 40px">
          <span style="margin-right: 40px"
            >下单时间：{{ item[0].placeTime }}</span
          >
          <span style="margin-right: 40px"
            >接单时间：{{ item[0].createTime }}</span
          >
        </div>
        <orderList
          :data.sync="item"
          :header="tableList.needHeader"
          @orderDetail="orderDetailFlybox"
          @delivery="delivery"
        />
      </div>
    </div>
    <listNull v-else text="这里什么也没有~" />
    <div v-if="total > 10" style="padding: 30px 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="订单详情"
      :visible.sync="showOrderDetail"
      width="60%"
      :before-close="handleClose"
    >
      <div class="detailFlybox">
        <el-divider content-position="left">基本信息</el-divider>
        <div class="order-list">
          <div class="order-item">订单号：{{ orderDetail[0].orderNo }}</div>
          <div class="order-item">
            支付方式：{{
              orderDetail[0].payType == ""
                ? "未支付"
                : orderDetail[0].payType == 1
                ? "微信支付"
                : "支付宝"
            }}
          </div>
          <div class="order-item">下单人：{{ orderDetail[0].userName }}</div>
          <div class="order-item">联系电话：{{ orderDetail[0].userPhone }}</div>
          <div class="order-item">
            配送方式：快递服务 <span style="margin-left: 20px"></span>收货人：{{
              orderDetail[0].consignee
            }}
            <span style="margin-left: 20px"></span>收货人手机：{{
              orderDetail[0].deliveryPhone
            }}
          </div>
          <div class="order-item">
            收货地址：{{ orderDetail[0].deliveryAddress }}
          </div>
          <div class="order-item">
            订单备注：{{ orderDetail[0].orderRemark }}
          </div>
          <!--<div class="order-item">发票：{{orderDetail[0].invoiceTitle}}</div>-->
          <div class="order-item">下单时间：{{ orderDetail[0].placeTime }}</div>
        </div>
        <orderList
          :data.sync="orderDetail"
          :header="tableList.needHeader2"
          @delivery="delivery"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { receivingList, updateReceivingOrder } from '@/api/order.js'
import { queryOrderForm, tabsList, tableList } from '../js/orderManage.js'
import { printrecevingqueryConfirmedPage, printrecevingdeliver } from '@/api/cubeclient.js'


import tabsCom from '../components/tabsCom.vue'
import formCom from '../components/formCom.vue'
import orderList from '../components/orderList.vue'
import listNull from '@/components/listNull.vue'
export default {
  name: 'orderConfirm',
  components: { formCom, tabsCom, orderList, listNull },
  data () {
    return {
      isQuery: false,
      showOrderDetail: false,
      queryOrderForm: queryOrderForm,
      tabsList: tabsList,
      tableList: tableList,
      data: [],
      orderDetail: [{}],
      tabsNumber: {},
      form: {},
      deftab: '',
      pageIndex: 1,
      limit: 10,
      total: 0
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    handleClick (name) {
      console.log(name)
      this.deftab = name
      this.data = []
      this.getData()
    },
    handleSizeChange (val) {
      this.limit = val
      this.getData()
    },
    handleCurrentChange (val) {
      this.pageIndex = val
    },
    getData (data) {
      this.data = []
      //       {
      //   "consignee": "",
      //   "deliveryPhone": "",
      //   "orderNo": "",
      //   "orderTimeBegin": "",
      //   "orderTimeEnd": "",
      //   "pageNo": 0,
      //   "pageSize": 0,
      //   "recevingTimeBegin": "",
      //   "recevingTimeEnd": "",
      //   "userPhone": ""
      // }
      printrecevingqueryConfirmedPage({
        orderNo: data ? data.orderCode : '',
        consignee: data ? data.consignee : '',
        deliveryPhone: data ? data.phoneNumber : '',
        orderStauts: this.deftab,
        orderTimeBegin: data ? data.publicTime ? data.publicTime[0] : '' : '',
        orderTimeEnd: data ? data.publicTime ? data.publicTime[1] : '' : '',
        pageNo: this.pageIndex,
        pageSize: this.limit
      }).then(res => {
        if (res.code === 200) {
          this.total = res.data.total
          if (res.data.list.length > 0) {
            for (const i of res.data.list) {
              const arr = []
              arr.push(i)
              this.data.push(arr)
            }
          } else {
            this.data = []
          }
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    delivery (row) {
      this.$prompt('请输入物流单号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        if (!value) {
          this.$message.error('请输物流编号')
          return
        }

        printrecevingdeliver({
          orderNo: row.orderNo,
          logisticsNo: value
        }).then(res => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '确认订单成功'
            })
            this.getData()
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消发货'
        })
      })
    },
    orderDetailFlybox (row) {
      this.orderDetail = [row]
      this.showOrderDetail = true
    },
    handleClose () {
      this.showOrderDetail = false
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  flex-direction: column;
  margin: 20px 30px;
  .detailFlybox {
    .order-list {
      display: flex;
      flex-wrap: wrap;
    }
    .order-item {
      width: 50%;
      line-height: 30px;
    }
  }
}
</style>
