<template>
<el-form :inline="true" :model="form">
  <el-form-item
    v-for="(item, index) of formList"
    :key="index"
    style="margin-right: 10px"
    :style="item.position"
    :label="item.label">
    <template v-if="item.type === 'input'">
      <el-input v-model="form[item.value]" :placeholder="item.text"></el-input>
    </template>
    <template v-if="item.type === 'select'">
      <el-select v-model="form[item.value]" :placeholder="item.text">
        <el-option
          v-for="i in item.list"
          :key="i.value"
          :label="i.label"
          :value="i.value">
        </el-option>
      </el-select>
    </template>
    <template v-if="item.type === 'datetimerange'">
      <el-date-picker
        v-model="form[item.value]"
        type="datetimerange"
        align="right"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        :value-format="item.format"
        :default-time="['00:00:00', '23:59:59']">
      </el-date-picker>
    </template>
    <template v-if="item.type === 'button'">
      <el-button :type="item.class" :size="item.size" @click="$emit(item.event, form)">{{ item.text }}</el-button>
    </template>
  </el-form-item>
</el-form>
</template>

<script>
export default {
  name: 'formCom',
  props: ['formList'],
  data () {
    return {
      form: {}
    }
  }
}
</script>

<style scoped>

</style>
