import Request from '@/lib/Request'
// const http = new Request('https://cubewebapi.51ylk.com', 10000)
// const http = new Request('http://192.168.1.65:8090', 3000)
const http = new Request(process.env.VUE_APP_URL)
// 查询待确认订单
export const toBeConfirmedList = params => {
  return http.post('/cube_web_api/merchant/toBeConfirmedList', params)
}
// 我的订单
export const receivingList = params => {
  return http.post('/cube_web_api/merchant/receivingList', params)
}
// 确认接单、取消接单
export const updateReceivingOrder = params => {
  return http.post('/cube_web_api/merchant/updateReceivingOrder', params)
}
// 更新订单
export const updateOrderState = params => {
  return http.post('/cube_web_api/order/updateOrderState', params)
}
// 印刷订单-检测订单状态
export const checkOrderStatus = params => {
  return http.post('/cube_web_api/order/checkOrderStatus', params)
}
