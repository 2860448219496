var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.data, border: "" }
        },
        _vm._l(_vm.header, function(item, index) {
          return _c("el-table-column", {
            key: index,
            attrs: {
              align: "center",
              prop: item.prop,
              label: item.label,
              width: item.width
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      item.type == "null"
                        ? _c("div", [_vm._v(_vm._s(scope.row[item.prop]))])
                        : _vm._e(),
                      item.type == "status"
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("fStatus")(
                                    scope.row[item.prop],
                                    item.list
                                  )
                                ) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      item.type == "rule"
                        ? _c(
                            "div",
                            { staticStyle: { "white-space": "break-spaces" } },
                            [
                              scope.row.productName
                                ? [
                                    _vm._v(_vm._s(scope.row.productName)),
                                    _c("br")
                                  ]
                                : _vm._e(),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("fAttr")(scope.row[item.prop])
                                  ) +
                                  " "
                              )
                            ],
                            2
                          )
                        : _vm._e(),
                      item.type == "download"
                        ? _c(
                            "div",
                            [
                              scope.row[item.prop]
                                ? [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: scope.row[item.prop],
                                          download: scope.row[item.prop],
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v("文件下载")]
                                    )
                                  ]
                                : [_vm._v(" 未上传 ")]
                            ],
                            2
                          )
                        : _vm._e(),
                      item.type === "control"
                        ? _c(
                            "div",
                            [
                              _vm._l(item.list, function(btn, i) {
                                return [
                                  btn.exclude
                                    ? [
                                        btn.value.includes(
                                          String(scope.row[btn.field])
                                        )
                                          ? _c(
                                              "el-button",
                                              {
                                                key: i,
                                                attrs: {
                                                  size: "mini",
                                                  type: btn.mold
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.$emit(
                                                      btn.event,
                                                      scope.row,
                                                      btn.args
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(btn.text) + " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    : [
                                        _c(
                                          "el-button",
                                          {
                                            key: i,
                                            attrs: {
                                              size: "mini",
                                              type: btn.mold
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.$emit(
                                                  btn.event,
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(btn.text))]
                                        )
                                      ]
                                ]
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }