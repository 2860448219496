<template>
  <div>
    <el-table :data="data" border style="width: 100%">
      <el-table-column
        align="center"
        v-for="(item, index) of header"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        :key="index"
      >
        <template slot-scope="scope">
          <div v-if="item.type == 'null'">{{ scope.row[item.prop] }}</div>
          <div v-if="item.type == 'status'">
            {{ scope.row[item.prop] | fStatus(item.list) }}
          </div>
          <div v-if="item.type == 'rule'" style="white-space: break-spaces">
            <template v-if="scope.row.productName"
              >{{ scope.row.productName }}<br
            /></template>
            {{ scope.row[item.prop] | fAttr }}
          </div>
          <div v-if="item.type == 'download'">
            <template v-if="scope.row[item.prop]">
              <a
                :href="scope.row[item.prop]"
                :download="scope.row[item.prop]"
                target="_blank"
                >文件下载</a
              >
            </template>
            <template v-else> 未上传 </template>
          </div>
          <div v-if="item.type === 'control'">
            <template v-for="(btn, i) of item.list">
              <template v-if="btn.exclude">
                <el-button
                  v-if="btn.value.includes(String(scope.row[btn.field]))"
                  size="mini"
                  :type="btn.mold"
                  :key="i"
                  @click="$emit(btn.event, scope.row, btn.args)"
                >
                  {{ btn.text }}
                </el-button>
              </template>
              <template v-else>
                <el-button
                  size="mini"
                  :type="btn.mold"
                  :key="i"
                  @click="$emit(btn.event, scope.row)"
                  >{{ btn.text }}</el-button
                >
              </template>
            </template>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'orderList',
  props: ['data', 'header'],
  filters: {
    fStatus (num, list, str = 'label') {
      // return 1

      for (const i of list) {
        if (i.value == num) { // 不要使用绝对等于
          return i[str]
        }
      }
    },
    fAttr (str) {

      if (!str) {
        return ""
      }
      const obj = JSON.parse(str)
      let content = ''
      try {
        for (const s of obj) {
          for (const i in s) {
            content += i + ':' + s[i] + '\n'
          }
        }
      } catch (e) {
        return str
      }
      return content
    }

  }
}
</script>

<style scoped>
</style>
