export const needOrderForm = [
  {
    label: '需求编号：',
    value: 'demandNo',
    type: 'input',
    text: '请输入需求订单编号',
    event: ''
  },
  {
    label: '下单人：',
    value: 'founder',
    type: 'input',
    text: '',
    event: ''
  },
  {
    label: '发布时间：',
    value: 'publicTime',
    type: 'datetimerange',
    format: 'yyyy-MM-dd HH:mm:ss', // timestamp
    text: '',
    event: ''
  },
  {
    label: '',
    value: '',
    position: 'float: right',
    size: '',
    class: 'primary',
    type: 'button',
    text: '搜索',
    event: 'query'
  }
]
export const queryOrderForm = [
  {
    label: '订单号：',
    value: 'orderCode',
    type: 'input',
    text: '请输入订单号',
    event: ''
  },
  {
    label: '收货人：',
    value: 'consignee',
    type: 'input',
    text: '请输入收货人',
    event: ''
  },
  // {
  //   label: '接单状态：',
  //   value: 'orderStauts',
  //   type: 'select',
  //   text: '请选择状态',
  //   list: [
  //     {
  //       label: '全部',
  //       value: '0'
  //     },
  //     {
  //       label: '待发货',
  //       value: '4'
  //     },
  //     {
  //       label: '待收货',
  //       value: '5'
  //     },
  //     {
  //       label: '已确认收货',
  //       value: '6'
  //     }
  //   ]
  // },
  {
    label: '联系电话：',
    value: 'phoneNumber',
    type: 'input',
    text: '请输入联系电话',
    event: ''
  },
  {
    label: '发布时间：',
    value: 'publicTime',
    type: 'datetimerange',
    format: 'yyyy-MM-dd HH:mm:ss',
    text: '',
    event: ''
  },
  {
    label: '',
    value: '',
    position: 'float: right',
    size: '',
    class: 'primary',
    type: 'button',
    text: '搜索',
    event: 'query'
  }
]
export const tabsList = {
  needOrder: [
    {
      label: '全部',
      prop: 'all',
      value: '0'
    },
    {
      label: '已取消',
      prop: 'cancelled',
      value: '2'
    }
  ],
  queryOrder: [
    {
      label: '全部',
      value: 'null'
    },
    {
      label: '待发货',
      value: '4'
    },
    {
      label: '待收货',
      value: '5'
    },
    {
      label: '已确认收货',
      value: '6'
    }
  ]
}
export const tableList = {
  needHeader: [
    {
      label: '货品规格',
      prop: 'skuCode',
      type: 'rule'
    },
    {
      label: '文件',
      prop: 'designFile',
      type: 'download'
    },
    {
      label: '货品金额',
      prop: 'price',
      type: 'null'
    },
    {
      label: '数量',
      prop: 'quantity',
      type: 'null'
    },
    {
      label: '订单金额',
      prop: 'orderAmount',
      type: 'null'
    },
    {
      label: '订单状态',
      prop: 'orderStatus',
      type: 'status',
      list: [
        {
          label: '待付款',
          value: 1
        }, {
          label: '已付款',
          value: 2
        }, {
          label: '待确认',
          value: 3
        },
        {
          label: '待发货',
          value: 4
        },
        {
          label: '已发货',
          value: 5
        },
        {
          label: '已收货',
          value: 6
        },
        {
          label: '已取消',
          value: 7
        },
        {
          label: '已失效',
          value: 8
        }
      ]
    },
    {
      label: '操作',
      prop: 'control',
      type: 'control',
      width: '250px',
      list: [
        {
          mold: 'primary',
          exclude: true,
          field: 'orderStatus',
          text: '确认',
          value: ['3'],
          event: 'confirm',
          args: 1
        },
        {
          mold: 'primary',
          exclude: true,
          field: 'orderStatus',
          text: '发货',
          value: ['4'],
          event: 'delivery'
        },
        {
          mold: 'success',
          text: '订单详情',
          event: 'orderDetail'
        },
        {
          mold: 'danger',
          exclude: true,
          field: 'orderStatus',
          text: '取消',
          value: ['3'],
          event: 'orderCalcel',
          args: 2
        }
      ]
    }
  ],
  needHeader2: [
    {
      label: '货品规格',
      prop: 'skuCode',
      type: 'rule'
    },
    {
      label: '文件',
      prop: 'designFile',
      type: 'download'
    },
    {
      label: '货品金额',
      prop: 'price',
      type: 'null'
    },
    {
      label: '数量',
      prop: 'quantity',
      type: 'null'
    },
    {
      label: '订单金额',
      prop: 'orderAmount',
      type: 'null'
    },
    {
      label: '订单状态',
      prop: 'orderStatus',
      type: 'status',
      list: [
        {
          label: '待付款',
          value: 1
        }, {
          label: '已付款',
          value: 2
        }, {
          label: '待确认',
          value: 3
        },
        {
          label: '待发货',
          value: 4
        },
        {
          label: '已发货',
          value: 5
        },
        {
          label: '已收货',
          value: 6
        },
        {
          label: '已取消',
          value: 7
        },
        {
          label: '已失效',
          value: 8
        }
      ]
    },
    {
      label: '操作',
      prop: 'control',
      type: 'control',
      width: '250px',
      list: [
        {
          mold: 'primary',
          exclude: true,
          field: 'orderStatus',
          text: '确认',
          value: ['3'],
          event: 'confirm',
          args: 1
        },
        {
          mold: 'primary',
          exclude: true,
          field: 'orderStatus',
          text: '发货',
          value: ['4'],
          event: 'delivery'
        },
        {
          mold: 'danger',
          exclude: true,
          field: 'orderStatus',
          text: '取消',
          value: ['3'],
          event: 'orderCalcel',
          args: 2
        }
      ]
    }
  ],
  myHeader: [
    {
      label: '货品规格',
      prop: 'skuAttr',
      type: 'rule'
    },
    {
      label: '文件',
      prop: 'fileUrl',
      type: 'download'
    },
    {
      label: '货品金额',
      prop: 'price',
      type: 'null'
    },
    {
      label: '数量',
      prop: 'purchaseCount',
      type: 'null'
    },
    {
      label: '订单金额',
      prop: 'orderAmount',
      type: 'null'
    },
    {
      label: '订单状态',
      prop: 'orderStatus',
      type: 'status',
      list: [
        {
          label: '待付款',
          value: 1
        }, {
          label: '已付款',
          value: 2
        }, {
          label: '待确认',
          value: 3
        },
        {
          label: '待发货',
          value: 4
        },
        {
          label: '已发货',
          value: 5
        },
        {
          label: '已收货',
          value: 6
        },
        {
          label: '已取消',
          value: 7
        }
      ]
    },
    {
      label: '操作',
      prop: 'control',
      type: 'control',
      width: '250px',
      list: [
        {
          mold: 'primary',
          exclude: true,
          field: 'orderStatus',
          text: '发货',
          value: ['4'],
          event: 'delivery'
        },
        {
          mold: 'primary',
          exclude: true,
          field: 'orderStatus',
          text: '待收货',
          value: ['5'],
          event: 'delivery'
        },
        {
          mold: 'primary',
          exclude: true,
          field: 'orderStatus',
          text: '已确认收货',
          value: ['6'],
          event: 'delivery'
        },
        {
          mold: 'success',
          text: '订单详情',
          event: 'orderDetail'
        }
      ]
    }
  ]
}
