var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tabs",
    {
      attrs: { type: "card" },
      on: {
        "tab-click": function($event) {
          return _vm.$emit("handleClick", _vm.deftab)
        }
      },
      model: {
        value: _vm.deftab,
        callback: function($$v) {
          _vm.deftab = $$v
        },
        expression: "deftab"
      }
    },
    _vm._l(_vm.list, function(item, index) {
      return _c("el-tab-pane", {
        key: index,
        attrs: { label: item.label, name: item.value }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }