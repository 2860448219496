<template>
  <el-tabs
    v-model="deftab"
    type="card"
    @tab-click="$emit('handleClick',deftab)">
    <el-tab-pane
      v-for="(item, index) of list"
      :label="item.label"
      :key="index"
      :name="item.value"></el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  name: 'tabsCom',
  props: ['list', 'tabsNumber'],
  data () {
    return {
      deftab: '0'
    }
  }
}
</script>

<style scoped>

</style>
